<template>
    <div>
        <b-modal title="Blagajna" v-model="modalActive" no-close-on-backdrop>
            <template #default>
                <validation-observer ref="validation">

                    <div class="w-100 d-flex justify-content-end">
                        <b-form-checkbox v-model="type_of_add" switch @change="resetClient">Izberi med že obstoječo blagajno</b-form-checkbox>
                    </div>


                    <div v-if="type_of_add">
                        <b-form-group label="Stranka">
                            <validation-provider #default="{ errors }" name="Stranka" rules="required">
                                <v-select :options="clients" placeholder="stranka" v-model="client" label="name"  @input="loadCashRegisters" appendToBody/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label="Blagajna" v-if="cash_registers && cash_registers.length > 0">
                            <validation-provider #default="{ errors }" name="poslovna enota" rules="required">
                                <v-select :options="cash_registers" placeholder="Izberite blagajno" :reduce="ele => ele.id" v-model="existing_cash_register.cash_register_id" label="cash_register_id" appendToBody/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <validation-provider #default="{ errors }" name="Lokalno omrežje" rules="required">
                            <b-form-checkbox v-model="existing_cash_register.local_network" switch>Blagajna v lokalnem omrežju</b-form-checkbox>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                    <div v-else>
                        <b-form-group label="Oznaka blagajne">
                            <validation-provider #default="{ errors }" name="Oznaka blagajne" rules="required|alpha-num">
                                <b-form-input v-model="object.cash_register_id" placeholder="BGXX"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Stranka">
                            <validation-provider #default="{ errors }" name="Stranka" rules="required">
                                <v-select :options="clients" placeholder="stranka" v-model="client" label="name" @input="loadBusinessUnits" appendToBody/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>


                        <b-form-group label="Poslovna enota" v-if="business_units && business_units.length > 0">
                            <validation-provider #default="{ errors }" name="poslovna enota" rules="required">
                                <v-select :options="business_units" placeholder="Izberite poslovno enoto" :reduce="ele => ele.id" v-model="object.business_unit_id" label="name" appendToBody/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <validation-provider #default="{ errors }" name="Lokalno omrežje" rules="required">
                            <b-form-checkbox v-model="object.local_network" switch>Blagajna v lokalnem omrežju</b-form-checkbox>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>

                </validation-observer>
            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="validate">
                    <span>
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Dodaj</span>
                    </span>
                </b-button>
            </template>
        </b-modal>
        <EssentialModal ref="essentialModal"/>
    </div>
</template>
<script>
    import {BButton, BFormGroup, BModal, BFormInput, BFormCheckbox} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, alphaNum } from '@validations'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'
    import EssentialModal from '@/views/components/EssentialModal.vue'


    export default {
        components: {
            EssentialModal,
            BFormInput,
            vSelect,
            BFormGroup,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            BFormCheckbox
        },
        data() {
            return {
                type_of_add: false,
                object: {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: true
                },
                business_units: [],
                cashRegister: null,
                modalActive: false,
                client: '',
                id: 0,
                required,
                alphaNum,
                clients: [],
                cash_registers: [],
                existing_cash_register: {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
            }
        },
        methods: {
            resetClient() {
                this.client = null
                this.object = {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
                this.existing_cash_register =  {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
            },
            async loadCashRegisters() {
                try {
                    this.existing_cash_register.cash_register_id = ''
                    this.object.cash_register_id = ''
                    const res = await this.$http.get(`/api/admin/v1/cash_registers/${this.client.id}`)

                    this.cash_registers = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            },
            async loadBusinessUnits() {
                try {
                    const res = await this.$http.get(`/api/admin/v1/client/business_units/${this.client.id}`)

                    this.business_units = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            },
            open(data) {
                this.object = {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
                this.cashRegister = data
                this.modalActive = true
                this.loadData()
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.type_of_add) {
                            this.addExistingCashRegister()
                        } else {
                            this.add()
                        }
                    }
                })
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/admin/v1/client/')

                    this.clients = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            },
            async addExistingCashRegister() {
                try {
                    await this.$http.post(`/api/admin/v1/cash_registers/reassign/${this.cashRegister.on_premise_token}/${this.client.id}/${this.existing_cash_register.cash_register_id}`, { local_network: this.existing_cash_register.local_network})
                    this.modalActive = false

                    this.existing_cash_register =  {
                        cash_register_id: '',
                        business_unit_id: '',
                        local_network: false
                    }
                    this.type_of_add = false
                    this.$emit('itemAdded')
                    this.$printSuccess('Posodobitev blagajne je bila uspešna!')
                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            },
            async add() {
                try {
                    await this.$http.post(`/api/admin/v1/cash_registers/add/${this.cashRegister.on_premise_token}/${this.client.id}`, this.object)
                    this.modalActive = false

                    this.object = {
                        cash_register_id: '',
                        business_unit_id: '',
                        local_network: false
                    }
                    this.$emit('itemAdded')
                    this.$printSuccess('Posodobitev blagajne je bila uspešna!')
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'cash register id exists in business unit')) {
                        this.$printWarning(this.$t('general.cash_register_id_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'insufficient tier')) {
                        await this.$refs.essentialModal.show(this.client.id)
                        this.modalActive = false
                    } else {
                        this.$printError('Prišlo je do napake!')
                    }
                }
            }
        }
    }
</script>
