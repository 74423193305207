<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Blagajne">
            <basic-table :columns="columns" :data="tills" v-slot="props">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-1" @click="$refs.modal.open(tills[props.row.originalIndex])"><feather-icon icon="EditIcon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 0">
                    {{props.row.on_premise_name}}
                </span>
                <span v-else-if="props.column.displayType === 3">
                    {{$dayjs(props.row.created_at).format('DD.MM.YYYY ob H:m:ss')}}
                </span>
                <span v-else-if="props.column.displayType === 1">
                    <div v-if="props.formattedRow[props.column.field]">
                        DA
                    </div>
                    <div v-else>
                        NE
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
        <add-cash-register ref="modal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import AddCashRegister from './Components/AddCashRegister.vue'

    export default {
        components: {BasicTable, BCard, BOverlay, BButton, AddCashRegister},
        data() {
            return {
                tills: [],
                columns: [
                    {
                        label: 'Naziv Blagajne',
                        field: 'on_premise_name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Priklopljena',
                        field: 'created_at',
                        displayType: 3,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    },
                    {
                        label: 'Akcije',
                        field: 'action',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/admin/v1/cash_registers/unadopted')
                    this.tills = response.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
