var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":"Blagajna","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.resetClient},model:{value:(_vm.type_of_add),callback:function ($$v) {_vm.type_of_add=$$v},expression:"type_of_add"}},[_vm._v("Izberi med že obstoječo blagajno")])],1),(_vm.type_of_add)?_c('div',[_c('b-form-group',{attrs:{"label":"Stranka"}},[_c('validation-provider',{attrs:{"name":"Stranka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clients,"placeholder":"stranka","label":"name","appendToBody":""},on:{"input":_vm.loadCashRegisters},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4181629818)})],1),(_vm.cash_registers && _vm.cash_registers.length > 0)?_c('b-form-group',{attrs:{"label":"Blagajna"}},[_c('validation-provider',{attrs:{"name":"poslovna enota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cash_registers,"placeholder":"Izberite blagajno","reduce":function (ele) { return ele.id; },"label":"cash_register_id","appendToBody":""},model:{value:(_vm.existing_cash_register.cash_register_id),callback:function ($$v) {_vm.$set(_vm.existing_cash_register, "cash_register_id", $$v)},expression:"existing_cash_register.cash_register_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3404136489)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Lokalno omrežje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.existing_cash_register.local_network),callback:function ($$v) {_vm.$set(_vm.existing_cash_register, "local_network", $$v)},expression:"existing_cash_register.local_network"}},[_vm._v("Blagajna v lokalnem omrežju")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,490443580)})],1):_c('div',[_c('b-form-group',{attrs:{"label":"Oznaka blagajne"}},[_c('validation-provider',{attrs:{"name":"Oznaka blagajne","rules":"required|alpha-num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"BGXX"},model:{value:(_vm.object.cash_register_id),callback:function ($$v) {_vm.$set(_vm.object, "cash_register_id", $$v)},expression:"object.cash_register_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stranka"}},[_c('validation-provider',{attrs:{"name":"Stranka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clients,"placeholder":"stranka","label":"name","appendToBody":""},on:{"input":_vm.loadBusinessUnits},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.business_units && _vm.business_units.length > 0)?_c('b-form-group',{attrs:{"label":"Poslovna enota"}},[_c('validation-provider',{attrs:{"name":"poslovna enota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.business_units,"placeholder":"Izberite poslovno enoto","reduce":function (ele) { return ele.id; },"label":"name","appendToBody":""},model:{value:(_vm.object.business_unit_id),callback:function ($$v) {_vm.$set(_vm.object, "business_unit_id", $$v)},expression:"object.business_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2518109504)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Lokalno omrežje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.local_network),callback:function ($$v) {_vm.$set(_vm.object, "local_network", $$v)},expression:"object.local_network"}},[_vm._v("Blagajna v lokalnem omrežju")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Dodaj")])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}}),_c('EssentialModal',{ref:"essentialModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }